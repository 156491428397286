<template>
  <page :footer='80'>
    <div>
      <header-bar>
        <div slot='leading' @click="$router.back()">
          <i style="font-size: 20px" class="iconfont icon-back"></i>
        </div>
      </header-bar>
      <canvas id='code' ref='code'></canvas>
      <div class="qrcode" v-if='imgUrl'>
        <img class="img" :src="imgUrl" alt="">
      </div>
      <empty v-else description='还没有公司码' />
      
    </div>
    <div slot='footer'>
      <button
        @click="$router.back()"
        class="margin_auto theme_bg btn btn_radius"
        style="width: 80%">
        确定
      </button>
    </div>
  </page>
</template>

<script>
import QRCode from 'qrcode'
import {Empty} from 'vant'

export default {
  data: ()=> ({
    imgUrl: ''
  }),
  components: {
    Empty
  },
  methods: {
    getCode() {
      const {companyCode} = this.$route.query
      if (companyCode) {
        QRCode.toDataURL(companyCode)
        .then(url => {
          // console.log(url)
          this.imgUrl = url
        })
        .catch(err => {
          console.error(err)
        })
      } else {
        this.imgUrl = ''
      }
    }
  },
  mounted() {
    this.getCode()
  }
}
</script>

<style lang="less" scoped>
.qrcode {
  position: absolute;
  top: 10vh;
  width: 60vw;
  height: 60vw;
  left: 20vw;
  padding: 10px;
  background: #fff;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 0 5px #eee;
}
</style>
